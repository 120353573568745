<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('newsletterSubsFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2" />
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right"></div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="newsletterSubs"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            v-on:refresh="() => filterNewsletter(filterParams)"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CButton
                  size="sm"
                  color="danger"
                  :disabled="!item.activeFlag"
                  @click="openModalEvent('update', 'confirm', item, 'Newsletter abonesini pasif et', 'Aboneliği pasif etmek üzeresin. Bu işlemi yapmak istediğine emin misin?')"
                >
                  Aboneliğini Pasif Yap
                </CButton>
              </td>
            </template>
            <template #emailValidFlag="{item}">
              <td>
                <CIcon
                  v-if="item.emailValidFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #activeFlag="{item}">
              <td>
                <CIcon
                  v-if="item.activeFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #createTime="{item}">
              <td>
                {{ item.createTime && dateFormat(item.createTime) }}
              </td>
            </template>
            <template #emailValidationTime="{item}">
              <td>
                {{ item.emailValidationTime && dateFormat(item.emailValidationTime) }}
              </td>
            </template>
            <template #lastInformTime="{item}">
              <td>
                {{ item.lastInformTime && dateFormat(item.lastInformTime) }}
              </td>
            </template>
            <template #lastOpenTime="{item}">
              <td>
                {{ item.lastOpenTime && dateFormat(item.lastOpenTime) }}
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterNewsletter"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import moment from 'moment'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'NewsletterSubs',
  components:{ ConfirmModal, FilterSidebar },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'notification',
      form: 'confirm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'newsletterSubsFilter',
      filterParams: {},
      lastItem: {},
      activePage: 1,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        { key: 'createTime', label: 'Kayıt_Tarihi', _style: 'font-size:12px' },
        { key: 'activeFlag', label: 'Aktiflik', _style: 'font-size:12px' },
        {
          key: 'emailAddress',
          label: 'Email Adresi',
          _style: 'font-size:12px'
        },
        {
          key: 'emailValidFlag',
          label: 'Emailden_Onay_Verildimi?',
          _style: 'font-size:12px'
        },
        {
          key: 'emailValidationTime',
          label: 'Email_Onay_Tarihi',
          _style: 'font-size:12px'
        },
        { key: 'langName', label: 'Dil', _style: 'font-size:12px' },
        {
          key: 'lastInformTime',
          label: 'Newsletter_Son_Gönderim.Tarihi.',
          _style: 'font-size:12px'
        },
        {
          key: 'lastOpenTime',
          label: 'Newsletter_Son_Açma_Tarihi.',
          _style: 'font-size:12px'
        },
        { key: 'ipAddress', label: 'IP_Adresi', _style: 'font-size:12px' }
      ],
      filters: [
        {
          type: 'date',
          dataIndex: 'createTimeStart',
          field: 'createTime',
          comparison: 'gt'
        },
        {
          type: 'date',
          dataIndex: 'createTimeEnd',
          field: 'createTime',
          comparison: 'lt'
        },
        {
          type: 'date',
          dataIndex: 'emailValidationTimeStart',
          field: 'emailValidationTime',
          comparison: 'gt'
        },
        {
          type: 'date',
          dataIndex: 'emailValidationTimeEnd',
          field: 'emailValidationTime',
          comparison: 'lt'
        },
        {
          type: 'date',
          dataIndex: 'lastInformTime',
          field: 'lastInformTimeStart',
          comparison: 'gt'
        },
        {
          type: 'date',
          dataIndex: 'lastInformTime',
          field: 'lastInformTimeEnd',
          comparison: 'lt'
        },
        {
          type: 'date',
          dataIndex: 'lastOpenTime',
          field: 'lastOpenTimeStart',
          comparison: 'gt'
        },
        {
          type: 'date',
          dataIndex: 'lastOpenTime',
          field: 'lastOpenTimeEnd',
          comparison: 'lt'
        },
        {
          type: 'boolean',
          dataIndex: 'activeFlag',
          field: 'activeFlag',
          comparison: 'eq'
        },
        {
          type: 'boolean',
          dataIndex: 'emailValidFlag',
          field: 'emailValidFlag',
          comparison: 'eq'
        },
        {
          type: 'string',
          dataIndex: 'emailAddress',
          field: 'emailAddress'
        },
        {
          type: 'string',
          dataIndex: 'ipAddress',
          field: 'ipAddress'
        }
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  computed: {
    reloadParams() {
      return [this.activePage]
    },
    newsletterSubs(){
      return this.$store.getters.newsletterSubs
    },
    loading(){
      return this.$store.getters.notificationLoading
    }
  },
  methods: {
    rowClicked(item) {
      this.lastItem = item
    },
    async openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },
    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },
    async crud(item) {
      let params = {
        emailAddress: item.emailAddress
      }
      await this.$store.dispatch('newsletterSubs_action', params)
      if(this.$store.getters.notificationStatus.success){
        this.openModalEvent()
        this.filterNewsletter(this.filterParams)
      }
    },
    async filterNewsletter(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('newsletterSubs_list', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  created() {
    this.filterNewsletter(this.filterParams)
  }
}
</script>
