<template>
     <CSidebar
      aside
      :show="show"
      colorScheme="light"
      overlaid
      size="xl"
      class="page-data-filter"
    >
        <CSidebarClose @click.native="openSidebarEvent" />
        <div class="flexCenter" v-if="loading">
            <CSpinner  color="primary"/>
        </div>
        <component v-else :is="customComponent" ref="customComponent" :params="data" :filter="filterHandle" ></component>
    </CSidebar>
</template>

<script>

    export default{
        name: 'Modal',
        props:{
            show: Boolean,
            openSidebarEvent: Function,
            operationEvent: Function,
            module: String,
            form: String,
            data: Object,
            loading: Boolean,
        },
        watch: {
            show: function(){
                if(this.$refs.customComponent)
                    this.$refs.customComponent.$refs.form.classList.remove('was-validated')
            }
        },
        computed:{
            customComponent: function(){
                if(this.module && this.form)
                    return () => import(`../${this.module}/filter/${this.form}` )
            }
        },
        methods:{
            filterHandle(){
                if(this.$refs.customComponent.$refs.form.checkValidity() == false){
                    this.$refs.customComponent.$refs.form.classList.add('was-validated')
                }else{
                    this.operationEvent(this.$refs.customComponent.data)
                }
                
            }
        }
    }

</script>
<style scoped>
 .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
 }
</style>